/* src/confer/client/audience/AudienceNavbar.css */
.audience-navbar {
    background-color: #f8f9fa;
    padding: 1rem 150px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative; /* Add this */
    z-index: 10; /* Add this */
}

.audience-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.audience-nav-title-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.audience-nav-title {
    margin: 0;
    font-size: 2rem;
    color: #1a73e8;
    font-weight: bold;
}

.audience-nav-subtitle {
    font-size: 1rem;
    color: #666;
}

.topic-name {
    font-weight: 600;
    color: #333;
}

.audience-connection-status {
    display: block;
    align-items: center;
    gap: 0.5rem;
}

.audience-connection-status .error {
    color: #dc3545;
    margin: 0;
}

/* Status colors */
.audience-status-text {
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.audience-status-connected {
    color: #28a745;
    background-color: rgba(40, 167, 69, 0.1);
}

.audience-status-connecting {
    color: #007bff;
    background-color: rgba(0, 123, 255, 0.1);
}

.audience-status-disconnected {
    color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
}

.audience-status-warning {
    color: #ffc107;
    background-color: rgba(255, 193, 7, 0.1);
}