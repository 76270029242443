/* src/confer/client/audience/MessageDisplay.css */
.audience-message-display {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 150px;
    background-color: #f5f5f5;
    height: 100%;
    overflow: hidden;
}

.audience-message-display .audience-message-list {  /* Make it specific to message display */
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

/* Remove the extra spacing when empty */
.audience-message-list.empty {
    margin-top: 0;
    align-items: center;
}

.audience-no-admin-notice {
    text-align: center;
    padding: 40px;
    color: #666;
    align-self: center;
}

.audience-no-admin-notice h2 {
    color: #333;
    margin-bottom: 15px;
}

.audience-message-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f0f8ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    padding-bottom: 30px; /* Space for audience-message-timestamp */
    max-width: 100%;
    word-wrap: break-word;
}

.audience-message-item .audience-image-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audience-message-item .audience-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    display: block;
    object-fit: contain;
}

.audience-message-item .audience-text-container {
    position: relative;
}

.audience-message-item .audience-text-container p {
    margin: 0;
    color: #2c5282;
    line-height: 1.5;
    word-wrap: break-word;
}

.audience-message-timestamp {
    font-size: 0.8em;
    color: #4a5568;
    position: absolute;
    bottom: 8px;
    right: 12px;
    margin: 0;
    padding: 2px 5px;
    background-color: rgba(250, 255, 255, 0.8);
    border-radius: 4px;
}

/* Add subtle border between messages */
.audience-message-item + .audience-message-item {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

/* Ensure proper spacing for the last message */
.audience-message-item:last-child {
    margin-bottom: 0;
}

/* Add these media queries directly in MessageDisplay.css */
@media screen and (max-width: 768px) {
    .audience-message-display {
        padding: 10px !important; /* Mobile padding */
    }

    .audience-message-item {
        width: 100% !important;
        margin: 10px 0 !important;
        box-sizing: border-box !important;
    }

    .audience-message-item .audience-image-container img {
        max-width: 100% !important;
        height: auto !important;
    }
}
