
.footer {
  /* border-bottom: 1px solid #d2d2d2; */
  border-top: 1px solid #d2d2d2;
  background-color: #0f2c4d;
  margin-top: auto;
}

.footer .info-content .contacts {
  /* padding-top: 3rem !important; */
}


.footer #email-contact {
  color: white !important;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer .social-app-icons {
  max-width: 250px;
  /* min-width: 250px; */
  display: flex;
  /* justify-content: space-between; */
}

.footer .social-app-icons a {
  line-height: 1;
  text-decoration: none;
  margin-right: 10px;
}

.footer .social-app-icons i {
  font-size: 1.2rem;
  color: #0f2c4d;
}

.social-app-icons .icon {
  /* margin: 0 20px; */
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  /* transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

.social-app-icons .icon span {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}


.social-app-icons .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: #fff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 1rem;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-app-icons .icon:hover .tooltip {
  top: -50px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-app-icons .icon:hover span {
  color: #fff;
}
/* .social-app-icons .icon:hover span, */
.social-app-icons .icon:hover .tooltip {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
}
.social-app-icons .icon:hover .tooltip,
.social-app-icons .icon:hover .tooltip::before {
  background: #c13584;
}


.footer .services {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  /* padding-top: 3rem !important; */
}
.footer .services #heading {
  font-size: 1.5rem;
  font-weight: 900;
}
.footer .services #heading button {
  background-color: #b0c5e4;
  border: none;
  box-shadow: none;
  border-radius: 5px;
}
.footer #email-contact .email {
  color: white;
  text-decoration: none;
  margin-right: 10px;
}
.footer #email-contact .email i {
  margin-right: 8px;
}
.footer .copy-right {
  color: white;
}
.footer .dash {
  border-top: 1px solid white;
}

@media only screen and (max-width: 500px) {
  .footer .copy-right {
    color: white;
    font-size: 8px;
  }
}




@media only screen and (max-width: 664px)  {
  /* small devices */
  .App,
  .responsive-width {
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (min-width: 664px) and (max-width: 768px) {
  /* //For Tablets */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}


@media screen and (min-width: 768px) and (max-width: 992px) {
  /* //For Laptops */
  .App,
  .responsive-width {
    /* width: 738px; */
    /* width: 850px; */
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  /* //For Large Laptops */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1920px) {
  /* //For Big TV's (HD Screens)  */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (min-width: 1920px) and (max-width: 3840px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
  .App,
  .responsive-width {
    width: 3810px;
    margin-left: auto;
    margin-right: auto;
  }
}
