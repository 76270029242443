.admin-dashboard {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.admin-message-controls {
    position: fixed;
    top: 140px;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px 150px; /* Consistent 150px padding */
    z-index: 1000;
    display: flex;
    gap: 10px;
    justify-content: center; /* Center the buttons */
}

/* Base button styles */
.admin-message-controls button {
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for color changes */
}

/* Specific style for the delete button */
.admin-message-controls button.delete-button {
    border-color: #dc3545; /* Red border */
}

/* Active state for delete button */
.admin-message-controls button.delete-button:not(:disabled) {
    background-color: #dc3545; /* Red background when active */
    border-color: #dc3545;
}

/* Hover state for delete button when active */
.admin-message-controls button.delete-button:not(:disabled):hover {
    background-color: #c82333; /* Darker red on hover */
    border-color: #bd2130;
}

/* Disabled state for all buttons */
.admin-message-controls button:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
    opacity: 0.65;
}

.admin-dashboard .admin-message-list {  /* Make it specific to admin dashboard */
    margin-top: 100px;
    padding: 20px 150px;
    overflow-y: auto;
    flex: 1;
    scroll-behavior: smooth;
    width: 100%;
    box-sizing: border-box;
}

.admin-message-item {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f0f8ff;
    box-shadow: 0 2px 4px rgba(135, 206, 235, 0.1);
    border: 1px solid rgba(135, 206, 235, 0.2);
    transition: background-color 0.3s ease;
    position: relative;
    padding-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
}

.admin-message-item:hover {
    background-color: #e6f3ff;
}

.admin-text-container {
    position: relative;
}

.admin-text-container p {
    margin: 0;
    color: #2c5282;
    line-height: 1.5;
    word-wrap: break-word;
}

.admin-image-container, .admin-text-container {
    position: relative;
}

.admin-image-container {
    position: relative;
    margin-bottom: 5px;
    width: 100%;
}

.admin-image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    display: block;
}

.admin-message-timestamp {
    font-size: 0.8em;
    color: #4a5568;
    position: absolute;
    bottom: 8px;
    right: 12px;
    margin: 0;
    padding: 2px 5px;
    background-color: rgba(250, 255, 255, 0.8);
    border-radius: 4px;
}

/* Optional: Add a subtle border to separate multiple messages */
.admin-message-item + .admin-message-item {
    border-top: 1px solid rgba(135, 206, 235, 0.1);
}