/* src/confer/client/admin/AdminAuth.css */
.admin-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.admin-auth .admin-auth-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.admin-auth form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.admin-auth h2 {
    text-align: center;
    margin: 0;
    color: #333;
    font-size: 1.8rem;
}

.admin-auth .admin-input-group {
    display: flex;
    flex-direction: column;
}

.admin-auth input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.admin-auth input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.admin-auth input::placeholder {
    color: #aaa;
}

.admin-auth button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.admin-auth button:hover {
    background-color: #0056b3;
}

.admin-auth button:active {
    transform: translateY(1px);
}

.admin-auth button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.admin-auth .admin-error-message {
    color: #dc3545;
    text-align: center;
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 4px;
    margin-top: -0.5rem;
}

/* Responsive design */
@media (max-width: 480px) {
    .admin-auth .admin-auth-container {
        margin: 1rem;
        padding: 1.5rem;
    }

    .admin-auth h2 {
        font-size: 1.5rem;
    }
}

/* Light mode support */
@media (prefers-color-scheme: light) {
    .admin-auth {
        background-color: #f5f5f5;
    }

    .admin-auth .admin-auth-container {
        background-color: #ffffff;
    }

    .admin-auth h2 {
        color: #333;
    }

    .admin-auth input {
        background-color: #ffffff;
        border-color: #ddd;
        color: #333;
    }

    .admin-auth input::placeholder {
        color: #aaa;
    }

    .admin-auth .admin-error-message {
        background-color: rgba(220, 53, 69, 0.1);
    }
}