/* src/confer/client/audience/AudiencePage.css */
.audience-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.message-display {
    flex: 1;
    padding: 20px 150px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 1px; /* Add this to prevent box-shadow overlap */
}

.no-admin-notice {
    text-align: center;
    padding: 40px;
    color: #666;
}

.no-admin-notice h2 {
    color: #333;
    margin-bottom: 15px;
}

.message-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.message-item {
    margin-bottom: 20px;
    padding: 15px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.message-item.image img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.timestamp {
    font-size: 0.8em;
    color: #888;
    margin-top: 5px;
    display: block;
}

.previous-session-notice {
    margin-top: 20px;
    color: #888;
    font-style: italic;
}

/* Add these media queries directly in AudiencePage.css */
@media screen and (max-width: 768px) {
    .message-display {
        padding: 10px !important;
        margin: 0 !important;
    }

    .message-list {
        padding: 5px !important;
    }

    .message-item {
        margin-bottom: 10px !important;
        padding: 10px !important;
    }
}